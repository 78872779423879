// THIS FILE IS AUTOGENERATED. DO NOT EDIT MANUALLY.

import answer_cell from './answer-call/problem.json';
import any_two_equal from './any-two-equal/problem.json';
import boolean_xnor from './boolean-xnor/problem.json';
import boolean_xor from './boolean-xor/problem.json';
import calculate_absolute from './calculate-absolute/problem.json';
import close_or_equal from './close-or-equal/problem.json';
import dating_filter from './dating-filter/problem.json';
import decimal_match from './decimal-match/problem.json';
import eat_outside from './eat-outside/problem.json';
import extract_decimal from './extract-decimal/problem.json';
import is_between from './float-in-between/problem.json';
import grade_contribution from './grade-contribution/problem.json';
import greater_difference from './greater-difference/problem.json';
import greater_than_double from './greater-than-double/problem.json';
import in_order from './in-order/problem.json';
import int_bool_equality from './int-bool-equality/problem.json';
import integer_conversion_loss from './integer-conversion-loss/problem.json';
import intersecting_metro from './intersecting-metro/problem.json';
import is_even from './is-even/problem.json';
import is_multiple from './is-multiple/problem.json';
import makes_ten from './makes-ten/problem.json';
import repeated_numbers from './repeat-numbers/problem.json';
import temperature_convert from './temperature-convert/problem.json';
import triangle_area from './triangle-area/problem.json';
import two_as_one from './two-as-one/problem.json';
const problems = [
    answer_cell,
    any_two_equal,
    boolean_xnor,
    boolean_xor,
    calculate_absolute,
    close_or_equal,
    dating_filter,
    decimal_match,
    eat_outside,
    extract_decimal,
    is_between,
    grade_contribution,
    greater_difference,
    greater_than_double,
    in_order,
    int_bool_equality,
    integer_conversion_loss,
    intersecting_metro,
    is_even,
    is_multiple,
    makes_ten,
    repeated_numbers,
    temperature_convert,
    triangle_area,
    two_as_one,
];
export default problems;
